<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
      <page-heading
        heading="New Checklist Template"
      />
    </column>
    <column class="is-narrow is-flex is-align-items-center">
      <router-link :to="{ name: 'checklist-templates' }">Back</router-link>
    </column>
  </columns>
  <form>
    <columns>
      <column>
        <text-input
          classes="is-rounded"
          :value="checklist.name"
          @input="updateName"
          :error="$root.errors.name"
          required
          description="A descriptive name for this template">
          Name
        </text-input>
      </column>
      <column>
        <number-input
          classes="is-rounded"
          :precision="0"
          :max="100"
          :min="0"
          :value="checklist.pass_percentage"
          @input="updatePassPercentage"
          :error="$root.errors.pass_percentage"
          description="If left as 0 this template will not be assessed for a pass or failure">
          Pass Percentage
        </number-input>
      </column>
      <column class="is-2">
        <data-selector
          classes="is-rounded"
          :items="checklist_types"
          value-key="value"
          label-key="label"
          :value="checklist.type"
          @input="updateType"
          :error="$root.errors.type"
          description="Indicates the type of checklist.">
          Type
        </data-selector>
      </column>
      <column>
        <data-selector
          classes="is-rounded"
          :items="classifications"
          value-key="uuid"
          label-key="name"
          :value="checklist.classification_uuid"
          @input="updateClassification"
          :error="$root.errors.classification_uuid"
          required
          description="Categorise this template">
          Classification

          <template #right>
            <action-button 
              @click="openQuickClassification"
              class="is-rounded">
              <icon icon="plus"/>
            </action-button>
          </template>
        </data-selector>
      </column>
    </columns>
    <columns>
        <column class="is-4">
          <time-input
            classes="is-rounded"
            required
            :value="checklist.estimated_duration"
            @input="updateEstimatedDuration"
            :error="$root.errors.estimated_duration"
            description="Estimated durations are used to compare real world durations on checklists vs expected time."
            hint="Format: 1h30m which translates to 1 hour 30 minutes">
            Estimated Duration of Checklist
          </time-input>
        </column>
        <column>
          <text-area
            classes="is-rounded"
            @input="updateDescription"
            :value="checklist.description">
            Description
          </text-area>
        </column>
      </columns>

    <segmented-teams />

    <columns>
      <column>
        <checkpoint-builder />
      </column>
    </columns>

    <columns v-if="checklist.type === 'equipment'">
      <column>
        <equipment-classification-builder/>
      </column>
    </columns>

    <columns>
      <column>
        <submit-button
          class="is-rounded"
          :working="creating"
          @submit="createChecklist">
          Save Template
        </submit-button>
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { checklistClassification as backend } from '@/api'
import EquipmentClassificationBuilder from './partials/EquipmentClassificationBuilder.vue'
import CheckpointBuilder from './partials/CheckpointBuilder.vue'
import { quickChecklistClassification } from '@/modals'
import SegmentedTeams from './partials/TeamSegmentation.vue'

export default {

  components: {
    EquipmentClassificationBuilder,
    CheckpointBuilder,
    SegmentedTeams
  },

  data: () => ({
    loading: false,
    creating: false,
    classifications: [],
  }),

  async beforeCreate() {
    await this.$store.dispatch('team/loadTeamList')
    await backend.loadList(({data}) => {
      this.classifications = data
    })
    this.loading = false
  },

  beforeDestroy() {
    this.clearChecklist()
  },

  methods: {
    ...mapMutations('checklist', [
      'updateName',
      'updatePassPercentage',
      'updateDescription',
      'clearChecklist',
      'updateClassification',
      'updateEstimatedDuration',
      'updateType'
    ]),
    createChecklist() {
      this.creating = true
      this.$store.dispatch('checklist/create').then(() => {
        this.creating = false
        this.$toast.success('Checklist successfully created')
        this.$router.push({
          name: 'checklist-templates'
        })
      }).catch(() => this.creating = false)
    },
    openQuickClassification() {
      quickChecklistClassification().then(classification => {
        if(classification) {
          this.classifications.push(classification)
          this.updateClassification(classification.id)
        }
      })
    }
  },

  computed: {
    ...mapGetters('checklist', [
      'checklist',
      'checklist_types'
    ])
  }

}
</script>